<template>
	<div id="landDelivery">
		<page-delivery-order :is-shop="1"></page-delivery-order>
	</div>
</template>
<script>
	import pageDeliveryOrder from '@/components/layout/land/page-delivery-order.vue'
	export default{
		components:{
			pageDeliveryOrder
		},
	}
</script>

<style>
</style>